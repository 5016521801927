import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollArrow from '../components/TopButton';
import Header from './daitsoHeader';
import Footer from './Footer';

import { useDispatch, useSelector } from 'react-redux';
import { isLogined } from '../api/auth';
import { GetUserMe } from '../api/user';
import { AuthSetUserData } from '../store/auth';

const MainContainerWrapper = styled.div`
	position: relative;
	& > .content {
		position: relative;
	}
`;

const MainContainer = (props) => {
	const Dispatch = useDispatch();
	const [is_load, set_is_load] = useState(0);
	const user_data = useSelector((state) => state.auth.user_data);

	const DoLogout = (e) => {
		e.preventDefault();
		alert('가입승인 대기중입니다.');
		localStorage.removeItem('user_token');
		window.location.href =
			'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=' +
			window.location.pathname;
	};

	// 다잇소계정
	// useEffect(() => {
	// 	localStorage.setItem(
	// 		'user_token',
	// 		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE3MTQ3MTY2MzMsIm5iZiI6MTcxNDcxNjYzMywiZXhwIjoyMDMwMDc2NjMzLCJkYXRhIjp7InVzZXIiOnsiaWQiOjkyNX19fQ.jBM39YdrnRetPOz94c6AbfIFNMVZTRIUKme5xXmqhMo'
	// 	);
	// }, []);

	// 멘토계정;
	// useEffect(() => {
	// 	localStorage.setItem(
	// 		'user_token',
	// 		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE3MDIyOTQ4MjIsIm5iZiI6MTcwMjI5NDgyMiwiZXhwIjoxNzAyODk5NjIyLCJkYXRhIjp7InVzZXIiOnsiaWQiOjkyNX19fQ.99dxtrf8X1oQzR-rZL1MNDpmIbdS5WnASba64bs5TCI'
	// 	);
	// }, []);

	// 멘티계정;
	// useEffect(() => {
	// 	localStorage.setItem(
	// 		'user_token',
	// 		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE3Mjk4NDc3NjMsIm5iZiI6MTcyOTg0Nzc2MywiZXhwIjoxNzMwNDUyNTYzLCJkYXRhIjp7InVzZXIiOnsiaWQiOjF9fX0.32yiTuR6e5nYKkPvirsfPoUHgf9nVzwV5mKJsOutQP4'
	// 	);
	// }, []);

	useEffect(() => {
		// 릴로 푸시토큰 로컬스토리지 저장
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const pushToken = params.get('pushToken');
		if (pushToken) {
			localStorage.setItem('pushToken', pushToken);
		}

		if (localStorage.getItem('user_token')) {
			// 수시로 토큰 체크
			isLogined()
				.then((response) => {
					if (response.data.data.status == 200) {
						console.log(response);
						GetUserMe()
							.then((response) => {
								console.log(response.data.body);
								if (response.data.body.user_role === 'subscriber') {
									alert('가입승인 대기중입니다.');
									localStorage.removeItem('user_token');
									localStorage.removeItem('user_data');
									window.location.href =
										'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=' +
										window.location.pathname;
								} else {
									if (response.data.body?.ID === 0) {
										localStorage.removeItem('user_token');
										localStorage.removeItem('user_data');
										window.location.href =
											'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=' +
											window.location.pathname;
									} else {
										Dispatch(AuthSetUserData(response.data.body));
										localStorage.setItem(
											'user_data',
											JSON.stringify(response.data.body)
										);
										console.log('543');
									}
								}
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						localStorage.clear();
						window.location.href =
							'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=https://daitso.kbds.co.kr';
					}
				})
				.catch((err) => {
					localStorage.clear();
					window.location.href =
						'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=https://daitso.kbds.co.kr';
				});
		} else {
			window.location.href =
				process.env.REACT_APP_BACK_URI +
				'/?ep_logout=1&redirect=' +
				window.location.protocol +
				'//' +
				window.location.host +
				window.location.pathname;
		}
	}, []);

	return (
		<MainContainerWrapper>
			<Header type={props.header_type} />
			<div className="content">{props.children}</div>
			<ScrollArrow />
			<Footer />
		</MainContainerWrapper>
	);
};

export default MainContainer;
