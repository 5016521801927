import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CloseModalBtn from '../../vector/CloseModalBtn';
import VolunteerPopup from '../../views/Story/Popup';
import { Overlay, SearchBar, SearchBarWrapper } from '../Header/styled';
import {
	Avatar,
	Dropdown,
	Hamburger,
	HeaderMenu,
	HeaderWrapper,
	Logo,
	SideMenu,
	WriteButton,
} from './styled';
import Mainlogo from '../../vector/Logo';

export const SearchToggle = styled.a`
	margin-top: 37px;
	display: inline-block;
	position: absolute;
	right: 300px;
	top: -5px;
	z-index: 1;
	path {
	}
	svg {
		width: 24px;
		height: 28px;
		cursor: pointer;
		margin-right: 5px;
	}
	@media ${(props) => props.theme.tablet} {
		.top & path {
			${(props) => (props.search_type == 'white' ? `fill: #fff;` : ``)}
		}
	}
	@media ${(props) => props.theme.mobile} {
		top: 33px;
		margin-top: 0px;
		right: auto;
		left: 144px;
		svg {
			height: 28px;
			margin-right: 20px;
		}
	}
`;

const searchIcon = (
	<svg
		width="25"
		height="25"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.7592 22.6908L19.2461 17.1777C20.5734 15.4108 21.2899 13.26 21.2875 11.05C21.2875 5.40508 16.6949 0.8125 11.05 0.8125C5.40508 0.8125 0.8125 5.40508 0.8125 11.05C0.8125 16.6949 5.40508 21.2875 11.05 21.2875C13.26 21.2899 15.4108 20.5734 17.1777 19.2461L22.6908 24.7592C22.9699 25.0086 23.3339 25.1418 23.708 25.1313C24.0822 25.1209 24.4382 24.9676 24.7029 24.7029C24.9676 24.4382 25.1209 24.0822 25.1313 23.708C25.1418 23.3339 25.0086 22.9699 24.7592 22.6908ZM3.7375 11.05C3.7375 9.60372 4.16637 8.18993 4.96988 6.98739C5.77339 5.78486 6.91544 4.8476 8.25163 4.29413C9.58781 3.74067 11.0581 3.59585 12.4766 3.87801C13.8951 4.16016 15.198 4.85661 16.2207 5.87928C17.2434 6.90195 17.9398 8.20492 18.222 9.6234C18.5041 11.0419 18.3593 12.5122 17.8059 13.8484C17.2524 15.1846 16.3151 16.3266 15.1126 17.1301C13.9101 17.9336 12.4963 18.3625 11.05 18.3625C9.11132 18.3602 7.25271 17.589 5.88185 16.2182C4.51099 14.8473 3.73983 12.9887 3.7375 11.05Z"
			fill="#fff"
		/>
	</svg>
);
const Header = (props) => {
	const Dispatch = useDispatch();
	const params = useLocation();
	const [dropdown_open, on_dropdown_open] = useState(0);
	const user_data = useSelector((state) => state.auth.user_data);
	const inputEl = useRef(null);
	const [search_input, update_search_input] = useState('');
	// console.log(user_data);

	const [menu_open, on_menu_open] = useState(0);
	const ToggleMenu = () => {
		if (!menu_open) {
			on_menu_open(1);
		} else {
			on_menu_open(0);
		}
	};
	const [search_open, on_search_open] = useState(0);
	const ToggleSearch = () => {
		if (!search_open) {
			on_search_open(1);
			inputEl.current.focus();
		} else {
			on_search_open(0);
		}
	};
	const HandleSearch = (e) => {
		update_search_input(e.target.value);
		if (e.key === 'Enter') {
			window.location.href = '/search/' + e.target.value;
		}
	};

	const AvatarToggle = () => {
		if (!dropdown_open) {
			on_dropdown_open(1);
		} else {
			on_dropdown_open(0);
		}
	};
	const DoLogout = (e) => {
		e.preventDefault();
		localStorage.removeItem('user_token');
		localStorage.clear();
		window.location.href =
			'https://wp.daitso.kbds.co.kr/?ep_logout=1&redirect=https://daitso.kbds.co.kr';
	};

	const [VolunteerPopup_isshow, show_VolunteerPopup] = useState(0);
	const showVolunteerPopup = (e) => {
		e.preventDefault();
		if (VolunteerPopup_isshow) {
			show_VolunteerPopup(0);
		} else {
			show_VolunteerPopup(1);
		}
	};

	// if (!user_data) {
	// 	return <></>;
	// }

	return (
		<>
			<HeaderWrapper
				header_type={props.type}
				is_home={params.pathname == '/' ? 'true' : ''}
			>
				<a href="/feed">
					<Logo type={props.type}>
						<i className="main-logo">
							<Mainlogo />
						</i>
						{/* <svg
              width="78"
              height="52"
              viewBox="0 0 78 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M62.0105 20.8867C61.1915 20.8867 60.4635 21.2811 60.4635 22.1911V23.8594C60.4635 26.4377 59.7355 27.8331 57.4908 29.3194C55.6708 30.4721 53.7598 31.5944 51.7882 32.6864C51.1815 33.0504 51.1512 33.93 51.5152 34.5367C51.9095 35.1737 52.6678 35.5074 53.3048 35.1434C55.2462 34.0817 57.1875 32.9291 58.8862 31.8371C60.9185 30.5327 61.7982 29.4711 62.0105 28.6521H62.0408C62.2228 29.4104 63.1025 30.472 65.1348 31.7764C66.8335 32.8684 68.8052 34.0211 70.7162 35.0827C71.3835 35.4467 72.1115 35.1131 72.5058 34.5064C72.8395 33.9301 72.8395 33.0504 72.2328 32.6864C70.2612 31.5944 68.3502 30.4721 66.5302 29.3194C64.2855 27.8331 63.5575 26.4377 63.5575 23.8594V22.1911C63.5575 21.2811 62.8295 20.8867 62.0105 20.8867ZM62.0105 34.7491C61.2218 34.7491 60.4938 35.1434 60.4938 36.0231V40.7247H50.3322C49.6042 40.7247 49.2705 41.3011 49.2705 41.9987C49.2705 42.6964 49.6042 43.3031 50.3322 43.3031H73.6888C74.4168 43.3031 74.7505 42.6964 74.7505 41.9987C74.7505 41.3011 74.4168 40.7247 73.6888 40.7247H63.5272V36.0231C63.5272 35.1434 62.7992 34.7491 62.0105 34.7491Z"
                fill="#fff"
              />
              <path
                d="M16.4967 22.6319H6.66867C4.90934 22.6319 4.33301 23.1779 4.33301 24.9069V37.5863C4.33301 39.3153 4.90934 39.8613 6.66867 39.8613C12.5533 39.8613 16.163 39.5883 18.984 39.1939C19.6513 39.1029 19.9243 38.4356 19.8333 37.7076C19.7727 37.0099 19.3177 36.4943 18.62 36.5853C15.344 37.0403 11.977 37.2526 8.00334 37.2526C7.54834 37.2526 7.36634 37.0706 7.36634 36.6156V25.8776C7.36634 25.4226 7.54834 25.2406 8.00334 25.2406H16.4967C17.2247 25.2406 17.5583 24.6643 17.5583 23.9666C17.5583 23.2386 17.2247 22.6319 16.4967 22.6319ZM23.261 20.5996C22.4723 20.5996 21.7443 20.9939 21.7443 21.8736V45.5336C21.7443 46.4133 22.4723 46.8076 23.261 46.8076C24.0497 46.8076 24.7777 46.4133 24.7777 45.5336V32.3993H28.1143C28.903 32.3993 29.2367 31.7926 29.2367 31.0646C29.2367 30.3366 28.903 29.7603 28.1143 29.7603H24.7777V21.8736C24.7777 20.9939 24.0497 20.5996 23.261 20.5996Z"
                fill="#fff"
              />
              <path
                d="M32.918 11.5352C32.1596 11.5352 31.4316 11.9295 31.4316 12.7788V26.5198C31.4316 27.3692 32.1596 27.7635 32.918 27.7635C33.7066 27.7635 34.4043 27.3692 34.4043 26.5198V12.7788C34.4043 11.9295 33.7066 11.5352 32.918 11.5352Z"
                fill="#fff"
              />
              <path
                d="M41.8855 11.293C41.1272 11.293 40.3992 11.6873 40.3992 12.567V15.6913H38.5792C37.8815 15.6913 37.5479 16.2676 37.5479 16.935C37.5479 17.6023 37.8815 18.1483 38.5792 18.1483H40.3992V26.8236C40.3992 30.3423 41.7642 31.859 45.0402 31.859C45.9805 31.859 46.8602 31.677 47.5275 31.3736C48.1342 31.1006 48.3769 30.494 48.1342 29.7963C47.8915 29.038 47.2849 28.856 46.7389 29.038C46.2535 29.22 45.7985 29.311 45.2525 29.311C43.9179 29.311 43.4022 28.6436 43.4022 26.8236V18.1483H46.9209C47.6185 18.1483 47.9522 17.6023 47.9522 16.935C47.9522 16.2676 47.6185 15.6913 46.9209 15.6913H43.4022V12.567C43.4022 11.6873 42.6742 11.293 41.8855 11.293Z"
                fill="#fff"
              />
              <circle cx="32.9334" cy="7.58281" r="1.95" fill="#FFDC00" />
            </svg> */}
					</Logo>
				</a>
				<HeaderMenu header_type={props.type}>
					<ul className={params.pathname != '/' ? 'sub' : ''}>
						<li
							className={
								params.pathname == '/cate/4493' ||
								params.pathname == '/cate/4493/'
									? 'active'
									: ''
							}
						>
							<Link to={'/cate/4493'}>기술/cop</Link>
						</li>
						<li
							className={
								params.pathname == '/cate/4494' ||
								params.pathname == '/cate/4494/'
									? 'active'
									: ''
							}
						>
							<Link to={'/cate/4494'}>워라밸/소모임</Link>
						</li>
						<li
							className={
								params.pathname == '/cate/4495' ||
								params.pathname == '/cate/4495/'
									? 'active'
									: ''
							}
						>
							<Link to={'/cate/4495'}>트렌드/생각</Link>
						</li>
						<li
							className={
								params.pathname == '/cate/4496' ||
								params.pathname == '/cate/4496/'
									? 'active'
									: ''
							}
						>
							<Link to={'/cate/4496'}>회사소식</Link>
						</li>
						<li
							className={
								params.pathname == '/cate/4850' ||
								params.pathname == '/cate/4850/'
									? 'active'
									: ''
							}
						>
							<Link to={'/cate/4850'}>이벤트</Link>
						</li>
					</ul>
				</HeaderMenu>
				<SearchToggle
					onClick={ToggleSearch}
					searchopen={search_open}
					search_type={props.type}
				>
					{searchIcon}
				</SearchToggle>
				<WriteButton
					href={process.env.REACT_APP_BACK_URI + '/wp-admin/post-new.php'}
				>
					새 글 작성
				</WriteButton>
				<Avatar url={user_data.user_avatar_url} onClick={AvatarToggle}>
					<Dropdown isopen={dropdown_open}>
						<li className="dropdown-mypage">
							<a href={'/'}>
								<span>
									<strong>{user_data.user_name} My Page</strong>
								</span>
							</a>
						</li>
						{/* <li>
							<a href={'https://litt.ly/daitso'} target="_blank">
								커뮤니케이션 허브
							</a>
						</li> */}
						{/* <li>
							<a
								href={'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'}
								target="_blank"
							>
								직원 FAQ
							</a>
						</li> */}
						<li onClick={showVolunteerPopup}>
							<a>봉사활동 추가</a>
						</li>
						<li>
							<a
								href={process.env.REACT_APP_BACK_URI + '/wp-admin/profile.php'}
								target="_blank"
							>
								프로필 수정
							</a>
						</li>
						{/* <li>
                            <a>전자명함 (준비중)</a>
                        </li> */}
						<li>
							<a
								href={process.env.REACT_APP_BACK_URI + '/wp-admin/'}
								target="_blank"
							>
								관리자 화면
							</a>
						</li>
						{user_data.user_role == 'administrator' ||
						user_data.dashboard_auth == 'is_admin' ? (
							<li>
								<a href={'/mentoring/admin'} target="_blank">
									멘토링 대시보드
								</a>
							</li>
						) : null}

						{user_data.dashboard_auth == 'is_depart' ? (
							<li>
								<a
									href={`/mentoring/${user_data.dashboard_depart}`}
									target="_blank"
								>
									부서장 대시보드
								</a>
							</li>
						) : null}

						<li>
							<a href="#" onClick={DoLogout}>
								로그아웃
							</a>
						</li>
					</Dropdown>
				</Avatar>
				<Hamburger onClick={ToggleMenu} isopen={menu_open} color={props.type}>
					<div>
						<i className="l" />
						<i className="s" />
						<i className="l" />
					</div>
				</Hamburger>
			</HeaderWrapper>
			<SideMenu isopen={menu_open}>
				<button onClick={ToggleMenu}>
					<i>
						<CloseModalBtn />
					</i>
				</button>
				<ul className={params.pathname != '/' ? 'sub' : ''}>
					<li className={params.pathname == '/technology' ? 'active' : ''}>
						<a href={'/cate/4493'}>기술/cop</a>
					</li>
					<li className={params.pathname == '/worklife' ? 'active' : ''}>
						<a href={'/cate/4494'}>워라밸/소모임</a>
					</li>
					<li className={params.pathname == '/trend' ? 'active' : ''}>
						<a href={'/cate/4495'}>트렌드/생각</a>
					</li>
					<li className={params.pathname == '/news' ? 'active' : ''}>
						<a href={'/cate/4496'}>회사소식</a>
					</li>
					<li className={params.pathname == '/event' ? 'active' : ''}>
						<a href={'/cate/4850'}>이벤트</a>
					</li>
					<li className="write-menu">
						<a
							href={process.env.REACT_APP_BACK_URI + '/wp-admin/post-new.php'}
							target="_blank"
						>
							새 글 작성
						</a>
					</li>
				</ul>
			</SideMenu>
			<SearchBarWrapper searchopen={search_open}>
				<SearchBar searchopen={search_open}>
					<input
						className="search__input"
						searchopen={search_open}
						onKeyPress={(e) => HandleSearch(e)}
						type="text"
						placeholder="Search"
						ref={inputEl}
					/>
					<div className="close" onClick={ToggleSearch}>
						<div></div>
						<div></div>
					</div>
				</SearchBar>
				<Overlay searchopen={search_open} onClick={ToggleSearch} />
			</SearchBarWrapper>
			<VolunteerPopup
				show={VolunteerPopup_isshow}
				onClose={showVolunteerPopup}
			/>
		</>
	);
};

export default Header;
